<style lang="less" scoped>
.contact-information {
  width: 100%;
  height: 100%;
  position: relative;
  &-first {
    position: relative;
    img {
      width: 100%;
    }
    &-absolute {
      width: 76.7%;
      max-width: 1104px;
      color: rgba(255, 255, 255, 1);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -64%);
      p:nth-child(1) {
        font-size: 20px;
        font-weight: 400;
        color: rgba(249, 98, 47, 1);
        line-height: 28px;
        letter-spacing: 3px;
      }
      p:nth-child(2) {
        font-size: 55px;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
        margin: 8px 0;
      }
      p:nth-child(3) {
        font-size: 40px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        letter-spacing: 5px;
        margin: 0;
      }
      .jiangnan-btn {
        width: 30%;
        margin-top: 67px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  &-second {
    width: 100%;
    height: 658px;
    background: #070911;
    position: relative;
    &-container {
      width: 76.7%;
      max-width: 1104px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: space-between;
      &-left {
        width: 49.8%;
        img {
          width: 100%;
        }
      }
      &-right {
        width: 42.2%;
        color: rgba(222, 230, 255, 1);
        font-weight: 400;
        p:nth-child(1) {
          font-size: 30px;
          line-height: 42px;
          letter-spacing: 4px;
          margin-bottom: 0px;
        }
        p:nth-child(2) {
          font-size: 10px;
          line-height: 14px;
          letter-spacing: 4px;
        }
        p:nth-child(3) {
          font-size: 14px;
          line-height: 30px;
          letter-spacing: 1px;
        }
      }
    }
  }
  &-third {
    width: 100%;
    height: 800px;
    background: #0f1118;
    position: relative;
    &-container {
      width: 59.6%;
      max-width: 858px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: rgba(222, 230, 255, 1);
      p:nth-child(1) {
        font-size: 30px;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: 4px;
        margin: 0;
      }
      p:nth-child(2) {
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 4px;
      }
      &-main {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &-slide {
          width: 30.3%;
          margin-top: 51px;
        }
        img {
          width: 100%;
          cursor: pointer;
        }
      }
      .r-button.r-button-up {
        margin: 67px auto;
      }
    }
  }
  &-forth {
    width: 100%;
    height: 658px;
    background: #070911;
    position: relative;
    &-container {
      width: 100%;
      max-width: 1440px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: rgba(222, 230, 255, 1);
      display: flex;
      justify-content: space-between;
      &-left {
        width: 50%;
        img {
          width: 100%;
        }
      }
      &-right {
        width: 50%;
        color: rgba(222, 230, 255, 1);
        text-align: left;
        &-main {
          width: 62.7%;
          margin-left: 11.8%;
          margin-top: 68px;
          p:nth-child(1) {
            font-size: 30px;
            font-weight: 400;
            line-height: 42px;
            letter-spacing: 4px;
            margin: 0;
          }
          p:nth-child(2) {
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 4px;
          }
          p:nth-child(3) {
            font-size: 14px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 1px;
            margin-bottom: 81px;
          }
        }
      }
    }
  }
  &-fifth {
    width: 100%;
    height: 800px;
    background: #0f1118;
    position: relative;
    &-container {
      width: 100%;
      max-width: 1440px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: rgba(222, 230, 255, 1);
      p:nth-child(1) {
        font-size: 30px;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: 4px;
        margin-bottom: 0;
      }
      p:nth-child(2) {
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 4px;
      }
      .r-button-2 {
        margin: 0 auto;
      }
      &-swiper {
        width: 88.9%;
        height: 440px;
        max-width: 1280px;
        margin: 80px auto;
        overflow: hidden;
        position: relative;
        .swiper-slide-item {
          width: 90%;
          margin-left: 5%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          &-child {
            width: 30%;
            height: 100%;
            position: relative;
            margin-top: 16px;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
            }
            &-hover {
              width: 95%;
              height: 90%;
              position: absolute;
              top: 2.9%;
              left: 2.5%;
              background: rgba(7, 9, 17, 0.59);
              &-title {
                margin-top: 30px;
                margin-right: 36px;
                text-align: right;
                img {
                  width: 14px;
                }
              }
              &-main {
                width: 80%;
                height: 43px;
                margin-left: 10%;
                // margin-top: 17%;
                margin-top: 32%;
                display: flex;
                &-left {
                  width: 20%;
                  img {
                    width: 38px;
                    height: 38px;
                  }
                }
                &-right {
                  width: 80%;
                  text-align: left;
                  p:nth-child(1) {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 1px;
                    margin: 0;
                  }
                  p:nth-child(2) {
                    font-size: 10px;
                    letter-spacing: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  /deep/
    .contact-information-fifth-container-swiper.swiper-container-initialized.swiper-container-horizontal
    > .swiper-button-next,
  .swiper-button-prev {
    width: 51px;
    height: 51px;
    background: rgba(222, 230, 255, 1);
    border-radius: 50%;
    color: #0f1118;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    // margin-left: 50px;
    // margin-right: 50px;
    &:after {
      font-size: 14px;
      font-weight: bolder;
    }
  }
  .r-button {
    width: 140px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    letter-spacing: 0;
    cursor: pointer;
    background: linear-gradient(
      211deg,
      rgba(248, 40, 108, 1) 0%,
      rgba(249, 98, 47, 1) 100%
    );
    border-radius: 29px;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
  }
}
</style>

<template>
  <div class="contact-information">
    <div class="contact-information-first">
      <img src="../assets/home_jiangnan.png"
           alt="">
      <div class="contact-information-first-absolute">
        <p>
          全球创意设计大赛·专属活动
        </p>
        <p>
          JINXIU JIANGNAN
        </p>
        <p>
          锦绣江南名品榜
        </p>
        <div class="jiangnan-btn">
          <div class="r-button jiangnan-btn1"
               @click="toProduction">
            我要上榜
          </div>
          <div class="r-button jiangnan-btn2"
               @click="toCompanyHomeCopy">
            我要推荐
          </div>
        </div>
      </div>
    </div>
    <div class="contact-information-second">
      <div class="contact-information-second-container">
        <div class="contact-information-second-container-left">
          <img src="../assets/home_jiangnan_1.png"
               alt="">
        </div>
        <div class="contact-information-second-container-right">
          <p>
            榜单简介
          </p>
          <p>
            INTRODUCTION
          </p>
          <p>
            为深入贯彻落实习近平总书记重要讲话精神，奋力夺取疫情防控和实现长三角经济社会发展目标“双胜利”，在长三角城市经济协调会办公室指导下，由长三角创意经济专业委员会（简称CECC）牵头组织长三角全球创意设计大赛及其专属“锦绣江南”名品榜活动。该活动已被列入长三角城市经济协调会2020年度重点合作事项清单。
          </p>
          <div class="r-button"
               @click="$router.push({name: 'brandActivity/details'})">
            {{$t('readMore')}}
          </div>
        </div>
      </div>
    </div>
    <div class="contact-information-third">
      <div class="contact-information-third-container">
        <p>
          名品榜单
        </p>
        <p>
          AWARDS
        </p>
        <div class="contact-information-third-container-main">
          <div class="contact-information-third-container-main-slide"
               v-for="(item, index) in AWARDSPrideList"
               :key="index"
               @mouseover="changeToRed(item)"
               @mouseleave="changeToYellow(item)"
               @click="toDesignProductList(item.type)">
            <img :src='item.showImg'
                 alt="">
          </div>
        </div>
        <div class="r-button r-button-up"
             @click="toProduction">
          我要上榜
        </div>
      </div>
    </div>
    <div class="contact-information-forth">
      <div class="contact-information-forth-container">
        <div class="contact-information-forth-container-left">
          <img src="../assets/home_jiangnan_2.png"
               alt="">
        </div>
        <div class="contact-information-forth-container-right">
          <div class="contact-information-forth-container-right-main">
            <p>
              年度城市
            </p>
            <p>
              INTRODUCTION
            </p>
            <p>
              丽水古名“处州”，市域面积1.73万平方公里，是浙江省面积最大的地级市，辖9个县（市、区）（莲都区、龙泉市、青田、云和、庆元、缙云、遂昌、松阳、景宁），总人口269万。丽水既有无与伦比的生态优势，又具备丰富的历史文化遗存，是“美不胜收的生态名城、积淀深厚的文化名城、心驰神往的旅游名城”。<br /><br />
              丽水是习总书记“绿水青山就是金山银山”思想的重要发源地。习总书记在浙江工作期间，先后八次来到丽水，深情寄语“绿水青山就是金山银山，对丽水来说尤为如此”“志不求易、事不避难”等殷殷嘱托。近年来，历届丽水市委、市政府始终以省委“八八战略”为总纲，在奋力打开“两山”通道上持续发力，经济社会保持了平稳较快发展。
            </p>
            <div class="r-button"
                 @click="toDesignProductList('annual_city')">
              {{$t('readMore')}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contact-information-fifth">
      <div class="contact-information-fifth-container">
        <p>
          全球原创名品
        </p>
        <p>
          TOP BRANDS
        </p>
        <div class="contact-information-fifth-container-swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="swiper-slide-item">
                <div class="swiper-slide-item-child"
                     v-for="(item, index) in topBrandsList"
                     :key="index"
                     @click="toCompanyHome(index)">
                  <img :src=item.hover
                       alt="">
                  <div class="swiper-slide-item-child-hover">
                    <!-- <div class="swiper-slide-item-child-hover-title">
                      <img src="../assets/p.png"
                           alt="">
                      {{item.count}}
                    </div> -->
                    <div class="swiper-slide-item-child-hover-main">
                      <div class="swiper-slide-item-child-hover-main-left">
                        <img :src=item.logo
                             alt="">
                      </div>
                      <div class="swiper-slide-item-child-hover-main-right">
                        <p>
                          {{item.name}}
                        </p>
                        <p>
                          {{item.description}}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div> -->
        </div>
        <!-- <div class="r-button r-button-2">
          更多名品
        </div> -->
      </div>
    </div>
    <Common-activies></Common-activies>
    <Common-footer></Common-footer>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
import CommonActivies from '../components/common-activities';
import CommonFooter from '../components/common-footer';

export default {
  name: 'contact-information',
  data () {
    return {
      topBrandsList: [
        {
          name: '胡庆余堂',
          description: '全国知名的老字号中药企业，是我国现存历史悠久的传统中药企业之一，享有“江南药王”盛誉。',
          logo: require('../assets/pp-hu.png'),
          hover: require('../assets/jn-hu.png'),
          count: 3630
        },
        {
          name: '古堰画乡',
          description: '好山好水，自然好酒。古堰画乡花果酒，于唇齿间留恋，在心中醉眠。',
          logo: require('../assets/pp-gu.png'),
          hover: require('../assets/jn-gu.png'),
          count: 3205
        },
        {
          name: '红蜻蜓',
          description: '以其时尚的外形，亲民的价格，贴心的服务，得到了广大消费者的认可及追捧。',
          logo: require('../assets/pp-hong.png'),
          hover: require('../assets/jn-h3.png'),
          count: 2970
        },
        {
          name: '珀莱雅',
          description: '海洋珀莱雅，发现你的美。致力于提供更安全有效的肌肤解决方案。',
          logo: require('../assets/pp-bo.png'),
          hover: require('../assets/jn-bo.png'),
          count: 2620
        },
        {
          name: 'Sandriver',
          description: 'SAND RIVER 是郭秀玲2004年创造的时尚羊绒自主品牌，拥有200多项技术专利。',
          logo: require('../assets/pp1.png'),
          hover: require('../assets/jn-h5.png'),
          count: 3630
        },
        {
          name: '森马',
          description: '森马集团有限公司以“创大众服饰名牌”为发展宗旨，形成了完整的市场网络格局。',
          logo: require('../assets/pp-sen.png'),
          hover: require('../assets/jn-sen.png'),
          count: 2620
        }
      ],
      AWARDSPrideList: [
        {
          showImg: require('../assets/price-1.png'),
          img: require('../assets/price-1.png'),
          imgH: require('../assets/price-h1.png'),
          type: 'city_impression'
        },
        {
          showImg: require('../assets/price-2.png'),
          img: require('../assets/price-2.png'),
          imgH: require('../assets/price-h2.png'),
          type: 'best_online'
        },
        {
          showImg: require('../assets/price-3.png'),
          img: require('../assets/price-3.png'),
          imgH: require('../assets/price-h3.png'),
          type: 'annual_city'
        },
        {
          showImg: require('../assets/price-4.png'),
          img: require('../assets/price-4.png'),
          imgH: require('../assets/price-h4.png'),
          type: 'recommendation'
        },
        {
          showImg: require('../assets/price-5.png'),
          img: require('../assets/price-5.png'),
          imgH: require('../assets/price-h5.png'),
          type: 'original'
        },
        {
          showImg: require('../assets/price-6.png'),
          img: require('../assets/price-6.png'),
          imgH: require('../assets/price-h6.png'),
          type: 'hot_comment'
        }
      ]
    }
  },
  created () {
  },
  mounted () {
    new Swiper('.contact-information-fifth-container-swiper', {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
  },
  methods: {
    linkTo (url, type) {
      if (type) {
        window.open(url, type);
      } else {
        this.$router.push({
          name: url
        })
      }
    },
    toProduction () {
      this.$router.push({
        name: 'personCenter',
        params: {
          from: 'jiangnan'
        }
      })
    },
    toCompanyHomeCopy () {
      if (!this.$Cookies.get('cecc-user')) {
        this.$router.push({
          name: 'login',
          params: {
            redirect: 'fairviewJiangnan'
          }
        })
      } else {
        if ([0, 3, 4].includes(JSON.parse(this.$Cookies.get('cecc-user')).user_type)) {
          this.$router.push({
            // name: 'building'
            name: 'recommend'
          })
        } else {
          this.$message.warning('您暂时还不是城市管理员');
        }
      }
    },
    toCompanyHome () {
      if (!this.$Cookies.get('cecc-user')) {
        this.$router.push({
          name: 'login',
          params: {
            redirect: 'fairviewJiangnan'
          }
        })
      } else {
        this.$router.push({
          name: 'building'
          // name: 'recommend'
        })
      }
      // this.$router.push({
      //   name: 'company',
      //   params: {
      //     id: id
      //   }
      // })
    },
    toDesignProductList (type) {
      if (!this.$Cookies.get('cecc-user')) {
        this.$router.push({
          name: 'login',
          params: {
            redirect: 'fairviewJiangnan'
          }
        })
      } else {
        // this.$router.push({
        //   name: 'building'
        // })
        this.$router.push({
          name: 'designProductList',
          params: {
            type: type
          }
        })
      }
    },
    changeToRed (item) {
      item.showImg = item.imgH;
    },
    changeToYellow (item) {
      item.showImg = item.img;
    }
  },
  components: {
    CommonActivies,
    CommonFooter
  }
}
</script>
